.Inicio {
  width: 100%;
  overflow: hidden; /* Evitar desbordamiento */
}
.Inicio img {
    width: 100%;
    height: 31rem;

}
.Carrusel {
    display: block;
    position: relative;
    width: 100%;
    z-index: 4;
}
.paquetes {
    width: 100%;
    height: auto;
    font-family: '-webkit-body';
}
.planMensual{
  width: 100%;
  height: auto;
  padding: 0%;
  margin-left: 0%;
  font-family: '-webkit-body';
  
}

.QuienesSomos {
  height: 1700px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  text-align: center;
  padding: 4%;
}
 .PlanesFamiliaresMain{
    width: 100%;
    height: 800px;
}
/*Css para titulo quienes somos */

.QuienesSomos h3 {
    position: relative; /* Posición relativa para permitir el posicionamiento de las líneas */
    display: inline-block; /* Hacer que el título ocupe solo el ancho necesario */
    padding: 0 30%; /* Espacio alrededor del título */
    font-size: 24px; /* Tamaño de fuente del título */
  }
  
  .QuienesSomos h3::before,
  .QuienesSomos h3::after {
    content: ''; /* Contenido vacío */
    position: absolute; /* Posición absoluta para las líneas */
    top: 50%; /* Posición vertical centrada */
    width: calc(50% - 50px); /* Ancho de las líneas (ajustar según sea necesario) */
    height: 1px; /* Grosor de las líneas */
    background-color: black; /* Color de las líneas */
  }
  
  .QuienesSomos h3::before {
    left: -6%; /* Línea izquierda */
  }
  
  .QuienesSomos h3::after {
    right: -6%; /* Línea derecha */
  }


  .footer{
    width: 100%;
    height: 172px;
  }
  .fotos3d{
    width: 100%;
    height: 30rem;
    padding-top: 12%;
  }