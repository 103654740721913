.video-container {
    width: 100%;
    overflow: hidden;
    /* Ajusta la altura según tus necesidades, por ejemplo, para mantener una proporción */
    height: 500px;
    position: relative;
}

.full-width-video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Hace que el video cubra todo el contenedor sin distorsionarse */
}
