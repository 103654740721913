.LoQueNosHaceDiferentesDiv {
    position: relative;
    width: 100%;
    height: 100%;
}

ul.ListaPrincipal {
    position: relative;
    width: 100%;
    height: 100%;
}

li.Lista1DelistaPricipal {
    width: 15%;
    position: absolute;
    top: 21%;
    left: 7%;
    border: 1px solid #00bfff;
    border-radius: 9px;
    background: #00bfff6b;
    height: 7%;
    display: flex;
    justify-content: space-around;
    align-content: flex-end;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    transition: all 0.3s ease;
    cursor: pointer;
}

li.Lista1DelistaPricipal:hover {
    border-color: #ff00ff;
    transform: scale(1.05);
}

li.Lista2DelistaPricipal {
    width: 22%;
    height: 35%;
    position: absolute;
    left: 27%;
    border: 1px solid #00bfff00;
    border-radius: 9px;
    background: #00bfff00;
    top: 11%;
}

.ListasegundariaDeLista2 li, .listadeargumento li {
    list-style-type: none; /* Oculta los puntos de la lista */
    padding: 0;
    border: 1px solid #000;
    border-radius: 10px;
    background: papayawhip;
    margin: 5%;
    transition: all 0.3s ease;
    cursor: pointer;
}

.ListasegundariaDeLista2 li:hover, .listadeargumento li:hover {
    background: #ffcc00;
    border-color: #ff00ff;
    transform: scale(1.05);
}

