  /* Asegúrate de importar la fuente Pacifico en tu proyecto */
  @import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

  nav.barraDeNavegacion {
      width: 100%;
      height: 3.5rem;
      display: flex;
      background: #ffffff;
      position: absolute;
      z-index: 5;
  }

  .sticky {
      position: fixed !important;
      top: 0%;
  }

  div.Logo {
      width: 30%;
      height: 3.5rem;
      margin-left: 8%;
  }

  div.Logo img {
      width: 60%;
      height: 100%;
      text-align: center;
  }

  div.Botones {
      width: 60%;
      height: 3.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .Botones button {
      width: 13%;
      height: 3.5rem;
      background: #ffffff;
      border: 0px solid #6495ed33;
      margin: 0% 0% 0% 1%;
      font-family: '-webkit-body';
      font-size: 21px;
  }

  .Botones button:hover {
      background: #0089ff3b;
      width: 14%;
  }

  @media screen and (max-width: 700px) {
    div.Logo {
        width: 30%;
        height: 3.5rem;
        margin-left: 5%;
        margin-top: 3%;
    }
    div.Logo img {
        width: 100%;
        height: auto;
        text-align: center;
    }
    
  }
  .Botones button {
    width: 22%;
    height: 3.5rem;
    background: #ffffff;
    border: 0px solid #6495ed33;
    margin: 0% 0% 0% 0%;
    font-family: '-webkit-body';
    font-size: 11px;
}
.Botones button:hover {
    background: #0089ff3b;
    width: 22%;
}
.SelectServicios{
    background-color: transparent;
    border: 0px solid transparent;
    font-family: system-ui;
    font-size: 11px;
    appearance: none;
    background-image: none;
    width: 24%;
    height: 3.5rem;
    text-align: center;

}
.select-container select:focus-visible {
    outline: none; /* Quita el borde cuando se presiona */
    border-color: transparent; /* Cambia el color del borde cuando se presiona */
}