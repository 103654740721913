.contact-form-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .contact-form-container h2 {
    margin-bottom: 20px;
  }
  
  .contact-form {
    position: relative;
    max-width: 400px; /* Ajusta el ancho según sea necesario */
    margin: 0 auto;
    padding: 20px;
    border: 2px solid #3788ff ;/* Color del borde */
    border-radius: 10px; /* Borde redondeado */
    overflow: hidden;
  }
  
  .contact-form input,
  .contact-form textarea,
  .contact-form button {
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
    border: none;
    border-bottom: 2px solid  #3788ff; /* Línea inferior de color azul */
    background-color: transparent; /* Fondo transparente */
    color:  #3788ff; /* Color del texto */
  }
  .BotonContactanos{
    background-color: #3788ff ;
  }
  .contact-form input:focus,
  .contact-form textarea:focus {
    outline: none;
    border-bottom: 2px solid #ff5722; /* Cambia el color de la línea inferior cuando se enfoca */
  }
  
  .contact-form button {
    background-color: hsla(240, 100%, 75%, 0.753); /* Color de fondo del botón */
    color: white; /* Color del texto del botón */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Transición suave del color de fondo */
  }
  
  .contact-form button:hover {
    background-color: #3788ff; /* Cambia el color de fondo del botón al pasar el mouse */
  }
  .Titulomensaje{
    text-align: center;
  }
  
  /* Efecto de líneas en movimiento */
  @keyframes moveLines {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  
  .contact-form::before,
  .contact-form::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: hsla(240, 100%, 75%, 0.753); /* Color de las líneas */
    animation: moveLines 2s infinite linear alternate; /* Animación de las líneas */
  }
  
  .contact-form::after {
    top: auto;
    bottom: 0;
  }
  