.alert {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url("https://www.clipstudio.net/wp-content/uploads/2019/09/0032_004.gif");
    border: 1px solid #b2d7e4; /* Cambiamos el borde a un color azul claro */
    border-radius: 20px; /* Aumentamos el radio del borde para que sea más suave */
    padding: 20px;
    max-width: 50%;
    z-index: 999;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); /* Agregamos una sombra suave */

}

.alert-content {
    position: relative;
    border: 1px solid #ccc; /* Añadimos un borde gris delgado al contenido */
    padding: 15px; /* Ajustamos el relleno para que las letras no toquen el borde */
}

.close-btn {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
}

.show {
    display: block;
}

.hide {
    display: none;
}

@media screen and (max-width: 700px) {
    .alert {
        max-width: 100%;
        width: 93%;
    }
}
