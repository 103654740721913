footer{
    width: 100%;
    height: 100%;
    background-color: rgb(0 4 16);
    border: 2px solid rgba(255, 224, 189, 1); 
    color: white;
    text-shadow: -1px -1px 0 #000,  
    1px -1px 0 #000,
   -1px  1px 0 #000,
    1px  1px 0 #000;
    padding: 2%;
    display: flex;
}

.DatosFuneraria{
    display: flex;
    width: 100%;
}
.Botonesfooter,.DatosDecontacto,.nombrefooter{
    width: 33%;
    font-size: 12px;
    height: 100%;
    

}
.Botonesfooter button {
    width: 21%;
    border-radius: 92px;
}
.nombrefooter{
    font-size: 18px;
    display: inline;
    text-align: center;
}
.nombrefooter img{
width: 30%;
height: 50%;

}
select {
    word-wrap: normal;
    border-radius: 22px;
}

@media screen and (max-width: 700px) {


    .footer {
        width: 100%;
        height: auto;
    }
    .DatosFuneraria {
        display: flex;
        width: 100%;
        flex-direction: column;
        flex-wrap: nowrap;
        text-align: center;
        padding: 8%;
    }
    .Botonesfooter, .DatosDecontacto, .nombrefooter {
        width: 100%;
        font-size: 12px;
        height: 100%;
        margin-bottom: 9%;
    }
    .Botonesfooter button {
        width: 32%;
        border-radius: 92px;
    }
}