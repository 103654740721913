.flotanteContacto {
    width: 6%;
    border: 1px solid #0000ff00;
    border-radius: 50%;
    position: fixed;
    z-index: 6;
    left: 90%;
    top: 80%;
}

.svg-inline--fa {
    height: 4em;

}
svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa {
    color: #64d448;
}

@media screen and (max-width: 700px) {
    .flotanteContacto {
        width: 20%;
        border: 1px solid #0000ff00;
        border-radius: 50%;
        position: fixed;
        z-index: 6;
        left: 74%;
        top: 80%;
    }
}