main {
    position: relative;
    width: 100%;
    height: 100%;
    display: ruby-text;
}

.IntroduccionQuienesSomos{
    width: 100%;
    font-size: 20px;
    text-align: center;
    font-family: fangsong;
   
}
.QuienesSomosUnicos{
    width: 100%;
    display: contents;
    height: 100%;
    
}
.Descripcion_Unicos{
    width: 40%;
    font-size: 20px;
    text-align: center;
    font-family: fangsong;
    border: 1px solid #00000054;
    background: #0000ff0a;
    border-radius: 20px;
    margin: 1%;
    
}

@media screen and (max-width: 700px) 
{
    .QuienesSomos {
        width: 100%;
        height: auto;
        padding: 4%;
        text-align: center;
    }

    .Izquierda, .Derecha {
        width: 100% !important;
        height: auto;
    }



}
