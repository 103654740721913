.ContenidoUnicos{
    width: 100%;
    height: 100%;
    display: flex;
    padding: 3%;
    justify-content: space-around;
    flex-wrap: wrap;
}
#Moviles{
    display: none;
}
#Computador{
    display: block;
}
.Izquierda,.Derecha{
    width: 40%;
    height: auto;

}
.LogoCompromiso{
    width: 100%;
    height: auto;
    display: inline-flex;
    justify-content: space-around;
}
.LogoCompromiso img{
    height: 100%;
    width: 100%;
    border-radius: 20px;
}

@media screen and (max-width: 700px) {
    #Moviles{
        display: block;
    }
    #Computador{
        display: none;
    }
    
}