.titulo {
  border-radius: 10px;
  color: #e6e0e0;
  font-size: 37px;
  font-weight: 700;
  left: 50%;
  padding: 10px 20px;
  position: absolute;
  text-align: center;
  top: 50%; 
  transform: translate(-50%, -50%);
  font-family: -webkit-body;
            text-shadow: 5px 5px 10px rgb(0, 0, 0);
}

#Uno{
  top: 15%;
}